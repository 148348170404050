import '../App.css';
import Navbar from './Navbar.js';

function About() {
  return (
    <>
      <Navbar/>
      <header className="App-header" style={{ textAlign: 'center' }}>
        <span style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <img src="IMG_1649.JPG" alt="..." className="responsive-img" />
          <img src="IMG_1650.JPG" alt="..." className="responsive-img" />
          <img src="IMG_1657.JPG" alt="..." className="responsive-img" />
        </span>
        <span style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <img src="IMG_1799.JPG" alt="..." className="responsive-img" />
          <img src="IMG_1892.JPG" alt="..." className="responsive-img" />
        </span>
        <div className="about-me-text">
          <h1 style={{ color: '#fff' }}>About me</h1>
          <h2 style={{ color: '#fff', padding: '0 20px' }}>Hello! My name is Tyler Kalkman. I am a third-year at Michigan Technological University studying Computer Science. I have always loved problem solving, 
            which is what drew me to want to pursue a career in computer science. Being able to apply my studies at multiple internships has shown me that I am truly in the correct field, 
            and I am even more excited to continue to improve and utilize my programming skills. In my free time I enjoy exploring, being active, listening to music, and making cool things. 
            I appreciate you taking your time to view my website, feel free to reach out to me using the information in the footer! </h2>
        </div>
  
      <style jsx>{`
        .responsive-img {
          width: 30%;
          opacity: 0.3;
          margin: 5px;
        }

        .about-me-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }

        @media (max-width: 768px) {
          .responsive-img {
            width: 45%;
          }

          h1, h2 {
            font-size: 90%;
          }

          .about-me-text {
            top: 15%;
            transform: translateY(0);
          }
        }

        @media (max-width: 480px) {
          .responsive-img {
            width: 100%;
          }

          h1 {
            font-size: 1.5em;
          }

          h2 {
            font-size: 1em;
          }

          .about-me-text {
            position: absolute;
            left: 0;
            margin-bottom: 20px;
          }
        }
      `}</style>
    </header>
      
    </>
  )
}

export default About;
import '../App.css';
import Navbar from './Navbar.js';

function Projects() {
  return (
    <>
      <Navbar/>
      <header className="App-header">
        <h1 style={{color:'#fff'}}>Projects</h1>
        <h1 style={{color:'#fff'}}>(Work in progress, check back soon!)</h1>
      </header>
    </>
  )
}

export default Projects;
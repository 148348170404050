import React from 'react';
import Navbar from './Navbar.js';

function Resume() {
  const objectStyle = {
    width: '100%',
    height: '1200px',
  };

  const mobileStyle = {
    height: '100vh', // Adjust height for mobile viewports
  };

  const smallerMobileStyle = {
    height: '80vh', // Adjust for even smaller screens
  };

  return (
    <>
      <Navbar />
      <header className="App-header">
        <a href="Resume.pdf" download>Download Resume</a>
        <object
          data="Resume.pdf"
          type="application/pdf"
          style={window.innerWidth <= 480 ? smallerMobileStyle : window.innerWidth <= 768 ? mobileStyle : objectStyle}
          aria-label="Resume"
        >
        </object>
      </header>
    </>
  );
}

export default Resume;